<template>
  <div>
    <page-header :isIndex="1"></page-header>
    <div class="wrapper">
      <div class="contents flex justify-between">
        <div class="version">
          <div class="process grid col-4" style="display:none">
            <div
              class="process-item"
              :class="['process-' + index, 'process-active-' + processsIndex]"
              v-for="(item, index) in processs"
              :key="index"
            >
              <div
                class="process-content flex justify-center"
                :class="item.action ? 'item-acitve' : ''"
              >
                <div class="item-num">{{ index + 1 }}</div>
                <div class="item-name">{{ item.name }}</div>
              </div>
            </div>
          </div>
          <div class="title">请选择您要的检测版本</div>
          <div class="version-top-content">
            <div class="version-top grid col-4">
              <div
                class="top-item"
                v-for="(item, index) in vers"
                :key="index"
                @click="handleVersion(item, index)"
              >
                <div
                  class="items"
                  :class="portalIndex == index ? 'item-acitve' : ''"
                >
                  <div
                    class="items-image contain"
                    :class="'images-' + index"
                  ></div>
                  <div class="items-ok">
                    <div class="ok-icon">
                      <div class="icon cover"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tips">
            <div class="tips-header flex justify-between">
              <div class="titles">
                <div class="title">
                  <span>{{ portalItem.title }}：</span>{{ portalItem.name }}
                </div>
                <div class="list" v-if="portalItem.list">
                  <div
                    class="list-item"
                    v-for="(item, index) in portalItem.list"
                    :key="index"
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
              <div
                class="lable flex"
                @mouseover="blockShow = true"
                @mouseleave="blockShow = false"
              >
                <div class="lable-icon cover"></div>
                <div class="lable-text">比对库范围</div>
              </div>
            </div>
            <div class="sj" :class="'sj-' + portalIndex"></div>
          </div>

          <div class="portal-block" v-if="blockShow">
            <div class="block-title flex justify-between">
              <div class="image cover" :class="'images-' + portalIndex"></div>
              <ul class="list">
                <li>
                  版本类型：<b style="color: #106dd4">{{
                    portalItem.mtitle
                  }}</b>
                </li>
                <li>
                  适用类型：<span>{{ portalItem.mtype }}</span>
                </li>
                <li>
                  检测语种：<span>{{ portalItem.mlang }}</span>
                </li>
              </ul>
            </div>
            <div class="block-content">
              <label>检测库范围：</label>
              <p>
                <span v-for="(child, num) in portalItem.mlist" :key="num">{{
                  child
                }}</span>
              </p>
            </div>
          </div>
          <div class="main">
            <h2>上传论文：</h2>
            <div
              class="input-item flex"
              v-for="(item, index) in portalItem.minput"
              :key="index"
            >
              <div class="title">{{ item.title }}<span v-if="item.title">：</span></div>
              <div class="input-con">
                <input
                  :type="item.type"
                  class="inputs"
                  v-model="ups[item.data]"
                  :placeholder="item.place"
                  v-if="item.type != 'select' && item.type !='checkbox'"
                />

                <input
                  v-model="ups.time"
                  v-if="item.type == 'select'"
                  type="text"
                  name="date"
                  id="date"
                  lay-verify="date"
                  :placeholder="item.place"
                  autocomplete="off"
                  class="inputs"
                />
                <div  v-if="item.type == 'checkbox'" class="input-checkbox">
                <input
                  type="checkbox"
                  autocomplete="off"
                  class="checkboxs"
                  @change="changeCheck"
                />
                <span>{{ item.place }}</span>
              </div>
              </div>
              <div class="label" v-if="item.type != 'select' && item.type !='checkbox'">
                <span>{{ filterLength(item) }}</span
                >/{{ item.max }}
              </div>
              <div class="ic-date contain" v-if="item.type == 'select'"></div>
            </div>

            <div class="layui-tab layui-tab-card">
              <ul class="layui-tab-title">
                <li class="layui-this">上传文档</li>
              </ul>
              <div class="layui-tab-content">
                <div class="layui-tab-item layui-show">
                  <div
                    class="layui-upload-drag"
                    id="upload_file_btn"
                    v-if="!fileName"
                  >
                    <i class="layui-icon"></i>
                    <p class="clickSm">点击上传，拖动文件到框内上传</p>
                    <p class="fuzhuSm">
                      支持文档格式：word文件(.doc .docx)
                      、文本文件(.txt)，文件大小在15M内
                    </p>
                    <p class="fuzhuSm" style="color: red">
                      注：请确保上传的文档一定是检测的文档；修订、批注模式会导致报告内容缺失。请核查论文内容后上传
                    </p>
                  </div>
                  <div class="file-html" v-if="fileName">
                    <div class="html-content">
                      <div class="file-title">{{ fileName }}</div>
                      <div
                        class="file-btn flex justify-center"
                        @click="uploadAgain"
                      >
                        <div class="btn">更换文档</div>
                      </div>
                      <!-- <p class="fuzhuSm" style="color: red">
                        注：更换文档是选择其他文档；若选择同一个文档，需要取消弹窗后重新选择
                      </p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="main-submit">
              <div class="btn" @click="handleSubmit">提交检测</div>
            </div>
          </div>
        </div>
        <page-side></page-side>
      </div>
    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import storeage from "store";
import { SubmitFile, Test } from "@/utils/http";
let UPLOAD_FILES;
export default {
  data() {
    return {
      vers: [
        {
          title: "维普论文检测大学生版",
          name: "适用大中专、本科阶段的毕业生。",
          list: null,
          mtitle: "大学生版",
          mtype: "适用大中专、本科阶段的毕业生",
          mlang: "中文、英文、繁体、小语种",
          mlist: [
            "中文科技期刊论文全文数据库",
            "中文主要报纸全文数据库",
            "中国专利特色数据库",
            "博士/硕士学位论文全文数据库",
            "中国主要会议论文特色数据库",
            "港澳台文献资源",
            "外文特色文献数据全库",
            "维普优先出版论文全文数据库",
            "互联网数据资源/互联网文档资源",
            "图书资源",
            "古籍文献资源",
            "年鉴资源",
            "IPUB原创作品",
          ],
          minput: [
            {
              title: "题 目",
              place: "请正确填写论文题目，且字数不超过160字",
              data: "title",
              max: 160,
              min: 0,
              type: "text",
            },
            {
              title: "作 者",
              place: "请正确填写作者信息，且字数不超过30字",
              data: "author",
              max: 30,
              min: 0,
              type: "text",
            },
            {
              title: "检测卡号",
              place: "请正确填写检测卡号，且字数不超过100字",
              data: "orderInfo",
              max: 100,
              min: 0,
              type: "text",
            },
            {
              title: "手机号码",
              place:
                "官网偶尔有检测卡顿、检测失败等情况，建议填写手机号，检测状态会及时通知",
              data: "phone",
              max: 11,
              min: 0,
              type: "number",
            },
            {
              title: "",
              place:
                "word描红报告 (限时免费赠送，如有需求，请勾选)",
              data: "DealWord",
              max: 11,
              min: 0,
              type: "checkbox",
            },
          ],
        },
        {
          title: "维普论文检测研究生版",
          name: "适用硕士/博士论文。",
          list: null,
          mtitle: "研究生版",
          mtype: "适用硕士/博士论文",
          mlang: "中文、英文、繁体、小语种",
          mlist: [
            "中文科技期刊论文全文数据库",
            "中文主要报纸全文数据库",
            "中国专利特色数据库",
            "博士/硕士学位论文全文数据库",
            "中国主要会议论文特色数据库",
            "港澳台文献资源",
            "外文特色文献数据全库",
            "维普优先出版论文全文数据库",
            "互联网数据资源/互联网文档资源",
            "图书资源",
            "古籍文献资源",
            "年鉴资源",
            "IPUB原创作品",
          ],
          minput: [
            {
              title: "题 目",
              place: "请正确填写论文题目，且字数不超过160字",
              data: "title",
              max: 160,
              min: 0,
              type: "text",
            },
            {
              title: "作 者",
              place: "请正确填写作者信息，且字数不超过30字",
              data: "author",
              max: 30,
              min: 0,
              type: "text",
            },
            {
              title: "检测卡号",
              place: "请正确填写检测卡号，且字数不超过100字",
              data: "orderInfo",
              max: 100,
              min: 0,
              type: "text",
            },
            {
              title: "手机号码",
              place:
                "官网偶尔有检测卡顿、检测失败等情况，建议填写手机号，检测状态会及时通知",
              data: "phone",
              max: 11,
              min: 0,
              type: "number",
            },
            {
              title: "",
              place:
                "word描红报告 (限时免费赠送，如有需求，请勾选)",
              data: "DealWord",
              max: 11,
              min: 0,
              type: "checkbox",
            },
          ],
        },
        {
          title: "维普论文检测编辑部版",
          name: "",
          list: [
            "1、适用于期刊、图书、会议论文、科技成果等领域，提供大数据的出版辅助审查。",
            "2、论文检测结果，并不能替代编辑部的审稿意见。投稿的接收标准，由各编辑出版单位自行定义。",
          ],
          mtitle: "编辑部版",
          mtype: "适用期刊投稿、会议论文、报告等",
          mlang: "中文、英文、繁体、小语种",
          mlist: [
            "中文科技期刊论文全文数据库",
            "中文主要报纸全文数据库",
            "中国专利特色数据库",
            "博士/硕士学位论文全文数据库",
            "中国主要会议论文特色数据库",
            "港澳台文献资源",
            "外文特色文献数据全库",
            "维普优先出版论文全文数据库",
            "互联网数据资源/互联网文档资源",
            "图书资源",
            "古籍文献资源",
            "年鉴资源",
            "IPUB原创作品",
          ],
          minput: [
            {
              title: "题 目",
              place: "请正确填写论文题目，且字数不超过160字",
              data: "title",
              max: 160,
              min: 0,
              type: "text",
            },
            {
              title: "作 者",
              place: "请正确填写作者信息，且字数不超过30字",
              data: "author",
              max: 30,
              min: 0,
              type: "text",
            },
            {
              title: "检测卡号",
              place: "请正确填写检测卡号，且字数不超过100字",
              data: "orderInfo",
              max: 100,
              min: 0,
              type: "text",
            },
            {
              title: "手机号码",
              place:
                "官网偶尔有检测卡顿、检测失败等情况，建议填写手机号，检测状态会及时通知",
              data: "phone",
              max: 11,
              min: 0,
              type: "number",
            },
            {
              title: "",
              place:
                "word描红报告 (限时免费赠送，如有需求，请勾选)",
              data: "DealWord",
              max: 11,
              min: 0,
              type: "checkbox",
            },
          ],
        },
        {
          title: "维普论文检测职称版",
          name: "适用职称评审论文、已发表论文",
          list: null,
          mtitle: "职称版",
          mtype: "适用职称评审论文、已发表论文",
          mlang: "中文、英文、繁体、小语种",
          mlist: [
            "中文科技期刊论文全文数据库",
            "中文主要报纸全文数据库",
            "中国专利特色数据库",
            "博士/硕士学位论文全文数据库",
            "中国主要会议论文特色数据库",
            "港澳台文献资源",
            "外文特色文献数据全库",
            "维普优先出版论文全文数据库",
            "图书资源",
            "古籍文献资源",
            "年鉴资源",
            "IPUB原创作品",
          ],
          minput: [
            {
              title: "题 目",
              place: "请正确填写论文题目，且字数不超过160字",
              data: "title",
              max: 160,
              min: 0,
              type: "text",
            },
            {
              title: "作 者",
              place: "请正确填写作者信息，且字数不超过30字",
              data: "author",
              max: 30,
              min: 0,
              type: "text",
            },
            {
              title: "检测卡号",
              place: "请正确填写检测卡号，且字数不超过100字",
              data: "orderInfo",
              max: 100,
              min: 0,
              type: "text",
            },
            {
              title: "手机号码",
              place:
                "官网偶尔有检测卡顿、检测失败等情况，建议填写手机号，检测状态会及时通知",
              data: "phone",
              max: 11,
              min: 0,
              type: "number",
            },
            {
              title: "发表时间",
              place: "请点击选择发表时间",
              data: "time",
              max: 0,
              min: 0,
              type: "select",
            },
            {
              title: "",
              place:
                "word描红报告 (限时免费赠送，如有需求，请勾选)",
              data: "DealWord",
              max: 11,
              min: 0,
              type: "checkbox",
            },
          ],
        },
      ],
      processs: [
        { name: "选择版本", action: true },
        { name: "确认支付", action: false },
        { name: "进行检查", action: false },
        { name: "下载报告", action: false },
      ],
      processsIndex: 0,
      portalIndex: -1,
      portalItem: {},

      blockShow: false,
      ups: {
      },
      fileName: "",
      files: null,
      links: "",
      uploadInst: null,
      uploadInfo: {},
      DealWord:0
    };
  },
  mounted() {
    let index = storeage.get("PortalIndex") || 0;
    if (index == "-1") {
      index = 0;
    }
    this.portalIndex = index;
    this.portalItem = this.vers[index];
    let that = this;
    setTimeout(() => {
      that.dateTimeFn();
    }, 200);
    that.uploadFileFn();
  },
  methods: {
    changeCheck(e){
      let checked = e.target.checked;
      this.DealWord = checked?1:0;
    },
    clearFile() {
      for (let x in UPLOAD_FILES) {
        delete UPLOAD_FILES[x];
      }
    },
    uploadAgain() {
      // this.files = "";
      // this.fileName = "";
      this.clearFile();
      this.uploadInst.reload(this.uploadInfo);
    },
    uploadFileFn() {
      let that = this;
      const layui = window.layui;
      layui.use(["upload"], function() {
        var upload = layui.upload;
        that.uploadInfo = {
          elem: "#upload_file_btn",
          exts: "doc|docx|txt",
          accept: "file",
          auto: false,
          url: "",
          size: 15 * 1024,
          choose: function(obj) {
            UPLOAD_FILES = obj.pushFile();
            obj.preview(function(index, file, result) {
              that.fileName = file.name;
              that.files = file;
              console.log("file", file);
            });
          },
          done: function(res) {
            //   layer.msg("上传成功");
          },
        };
        that.uploadInst = upload.render(that.uploadInfo);
      });
    },
    filterLength(e) {
      let value = this.ups[e.data];
      if (value) {
        let len = value.length;
        if (len > e.max) {
          len = e.max;
          let str = value.slice(0, e.max);
          this.ups[e.data] = str;
        }
        return len;
      }
      return 0;
    },
    handleVersion(item, index) {
      this.ups = {
       
      };
      this.portalIndex = index;
      this.portalItem = item;
      // this.fileName = "";
      // this.files = "";
      storeage.set("PortalIndex", index);

      // if (index == 0 || index == 3) {
      //   this.ups = {};
      //   this.portalIndex = index;
      //   this.portalItem = item;
      //   storeage.set("PortalIndex", index);
      // }
      let that = this;
      that.clearFile();
      setTimeout(() => {
        that.uploadInst.reload(that.uploadInfo);
        that.dateTimeFn();
      }, 200);
    },
    dateTimeFn() {
      let that = this;
      const layui = window.layui;
      layui.use(["laydate"], function() {
        var laydate = layui.laydate;
        //日期
        laydate.render({
          elem: "#date",
          done: (value) => {
            that.ups.time = value;
          },
        });
      });
    },
    msgFn(data) {
      layer.alert(data);
    },
    isFile() {
      let portalIndex = this.portalIndex + 1;
      const formData = new FormData();
      formData.append("file", this.files);
      formData.append("orderInfo", this.ups.orderInfo || "");

      formData.append("title", this.ups.title);
      formData.append("author", this.ups.author);
      if (this.ups.phone) {
        formData.append("phone", this.ups.phone || "");
      }
      console.log(portalIndex);
      
      formData.append("DealWord", this.DealWord||0);
      formData.append("type", portalIndex);
      if (this.portalIndex == 3) {
        formData.append("time", this.ups.time || "");
      }

      return formData;
    },
    handleSubmit(item) {
      // console.log(this.ups);
      // return;
      let type = item.type;
      if (type == 2) {
        if (this.links) {
          window.open(this.links, "_blank");
        }

        return false;
      }
   
      if (!this.ups.title) {
        return this.msgFn("请输入论文标题");
      }
      if (!this.ups.author) {
        return this.msgFn("请输入论文作者");
      }
      if (!this.ups.orderInfo) {
        return this.msgFn("请输入检测卡号");
      }
   

      if (!this.files) {
        return this.msgFn("请上传文件");
      }

      let reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/;

      if (this.ups.phone) {
        let phone = reg.test(this.ups.phone);
        if (!phone) {
          return this.msgFn("手机号不正确");
        }
      }
      layer.load(1, {
        shade: [0.1, "#000"],
      });
      let ups = this.isFile();

      SubmitFile(ups)
        .then((res) => {
          console.log(res);
          this.ups = {};
          this.fileName = "";
          this.files = null;
          layer.closeAll();
          this.msgFn(res.info);
        })
        .catch((err) => {
          layer.closeAll();
          this.msgFn(err.info);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 1200px;
  min-width: 1200px;
}
.contents {
  position: relative;
  width: 100%;
  padding-top: 120px;
  padding-bottom: 80px;

  .sidebar {
    width: 280px;
    flex-shrink: 0;
    margin-left: 20px;
  }
  .version {
    width: 100%;
    background: #fff;
    position: relative;
    .title {
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 24px;
      font-weight: 600;
    }
  }
}
.version-top-content {
  position: relative;
  padding-top: 30px;
  height: 100px;
}
.version-top {
  position: absolute;
  left: -10px;
  right: -10px;
  .items {
    padding: 0 10px;
    position: relative;
    cursor: pointer;
    &.item-acitve {
      .items-ok {
        display: block;
      }
    }
    .items-ok {
      position: absolute;
      top: 0;
      left: 10px;
      right: 10px;
      bottom: 0;
      background: rgba($color: #000000, $alpha: 0.4);
      z-index: 2;
      border-radius: 4px;
      display: none;
      .ok-icon {
        width: 52px;
        height: 52px;
        background: #fff;
        border-radius: 52px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .icon {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 36px;
          height: 36px;
          transform: translate(-50%, -50%);
          background-image: url("~@/assets/ok.png");
        }
      }
    }
  }
  .items-image {
    width: 100%;
    height: 97px;
    border-radius: 4px;
    overflow: hidden;
  }
}
.images-0 {
  background-image: url("~@/assets/dxs.png");
}
.images-1 {
  background-image: url("~@/assets/yjs.png");
}
.images-2 {
  background-image: url("~@/assets/bjbb.png");
}
.images-3 {
  background-image: url("~@/assets/zcrdb.png");
}
.process {
  height: 32px;
  width: 100%;
  color: #8f9299;
  background: #d4d6da;
  margin-bottom: 40px;
  .process-item {
    height: 32px;
    line-height: 32px;
    font-size: 13px;
    position: relative;
    .process-content {
      height: 32px;
      line-height: 32px;
      width: 100%;
      position: relative;
    }

    .item-num {
      width: 20px;
      height: 20px;
      margin-top: 6px;
      line-height: 20px;
      background: #fff;
      text-align: center;
      color: #8f9299;
      border-radius: 20px;
      font-weight: 500;
    }
    .item-name {
      color: #8f9299;
      padding-left: 10px;
    }
  }
  .item-acitve {
    background: #d1ae80;
    color: #fff;
    .item-num {
      color: #d1ae80;
    }
    .item-name {
      color: #fff;
      font-weight: 600;
    }
  }
  .process-item::before {
    position: absolute;
    content: "";
    width: 19px;
    height: 32px;
    top: 0;
    right: -9px;
    background: url("~@/assets/p0.png") no-repeat center;
    background-size: cover;
    z-index: 1;
  }
  .process-3::before {
    display: none;
  }
  .item-acitve::before {
    position: absolute;
    content: "";
    width: 19px;
    height: 32px;
    top: 0;
    right: -9px;
    z-index: 1;
    background: url("~@/assets/p1.png") no-repeat center;
    background-size: cover;
  }
}
.process-active-1.process-0 {
  .item-acitve::before {
    background-image: url("~@/assets/p2.png");
  }
}

.process-active-2.process-0,
.process-active-2.process-1 {
  .item-acitve::before {
    background-image: url("~@/assets/p2.png");
  }
}

.process-active-3.process-0,
.process-active-3.process-1,
.process-active-3.process-2 {
  .item-acitve::before {
    background-image: url("~@/assets/p2.png");
  }
}
.process-active-3.process-3 {
  .item-acitve::before {
    display: none;
  }
}

.tips {
  background-color: #f0f7ff;
  box-sizing: border-box;
  padding: 0 20px;
  margin-top: 22px;
  position: relative;
  .titles {
    font-size: 14px;
    .title {
      height: 48px;
      line-height: 48px;
      font-size: 14px;
      color: #1272d7;
      font-weight: normal;
      text-align: left;
      span {
        color: #1272d7;
        font-weight: 600;
      }
    }
    .list {
      clear: both;
      padding-bottom: 10px;
      .list-item {
        font-weight: normal;
        font-size: 14px;
        color: #1272d7;
        line-height: 26px;
      }
    }
  }
}
.lable {
  cursor: pointer;
  .lable-icon {
    width: 14px;
    height: 14px;
    margin-top: 17px;
    background-image: url("~@/assets/q.png");
  }
  .lable-text {
    color: #1272d7;
    font-size: 14px;
    height: 48px;
    line-height: 48px;
    padding-left: 5px;
  }
}
.sj {
  position: absolute;
  left: 95px;
  top: -10px;
  width: 0;
  height: 0;
  border-bottom: 10px solid #f0f7ff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  &.sj-1 {
    left: 325px;
  }
  &.sj-2 {
    left: 555px;
  }
  &.sj-3 {
    left: 785px;
  }
}
.portal-block {
  position: absolute;
  top: 60px;
  width: 600px;
  min-height: 440px;
  left: 50%;
  margin-left: -300px;
  background-color: #fff;
  box-shadow: 4px 0 60px rgb(0 0 0 / 15%);
  padding: 40px 52px 54px 52px;
  box-sizing: border-box;
  z-index: 99;
  .block-title {
    height: 94px;
    width: 100%;
    .image {
      width: 204px;
      height: 94px;
    }
    .list {
      float: left;
      padding-left: 22px;
      padding-top: 12px;
      li {
        line-height: 22px;
        font-size: 13px;
        color: #6b7077;
        b {
          color: #34353c;
          font-size: 13px;
        }
      }
    }
  }
  .block-content {
    padding-top: 24px;
    li,
    label {
      line-height: 22px;
      font-size: 13px;
      color: #6b7077;
    }
    p {
      padding-top: 10px;
      span {
        display: inline-block;
        width: 246px;
        text-align: left;
        line-height: 28px;
        color: #34353c;
        font-size: 13px;
      }
    }
  }
}
.main {
  position: relative;
  padding-top: 10px;
  h2 {
    margin-top: 38px;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
  }
  .input-item {
    margin-top: 22px;
    line-height: 46px;
    border: 1px solid #d2dbe6;
    overflow: hidden;
    position: relative;
    .title {
      width: 80px;
      color: #34353c;
      font-size: 14px;
      text-align: right;
      font-weight: normal;
    }
    .input-con {
      width: 736px;
      line-height: 46px;
      height: 46px;
      border: 0 none;
      box-sizing: border-box;
      padding: 0 10px 0 10px;
      .inputs {
        border: none;
        outline: none;
        width: 100%;
        line-height: 46px;
        height: 46px;
      }
    }
    .label {
      text-align: right;
      padding-left: 20px;
      color: #808b9b;
    }
  }
}
.ic-date {
  width: 20px;
  height: 46px;
  margin-left: 22px;
  background-image: url("~@/assets/d.png");
}
.main-submit {
  height: 48px;

  .btn {
    cursor: pointer;
    width: 162px;
    height: 48px;
    text-align: center;
    background-color: #ff311c;
    display: block;
    line-height: 48px;
    font-size: 16px;
    color: #fff;
    margin-top: 20px;
    border-radius: 2px;
    transition: 0.6s opacity;
    &:hover {
      opacity: 0.8;
      color: #ffffff;
    }
  }
}

.layui-tab-card {
  box-shadow: none;
  margin: 22px 0;
  border: none;

  border-bottom: 1px solid #d2dbe6;
  border-left: 1px solid #d2dbe6;
}
.layui-tab-title {
  background: none;
  border-color: #d2dbe6;
}
.layui-this {
  width: 140px;

  border-top: 3px solid #ff6565;
  &::after {
    border-color: #d2dbe6;
  }
}
.layui-tab-content {
  padding: 24px;
  border-right: 1px solid #d2dbe6;
}
.layui-tab-item {
  width: 100%;
  height: 172px;
}
.layui-upload-drag {
  width: 100%;
  height: 172px;
  padding: 0;
  padding-top: 20px;
  margin: 0;
  border: none;
}

.layui-icon {
  display: inline-block;
  width: 135px;
  height: 40px;
  background: #3a89ff;
  position: relative;
  font-size: 0px !important;
  border-radius: 47px;
  background-image: url("~@/assets/p.png");
  background-repeat: no-repeat;
  background-position-x: 28px;
  background-position-y: center;
  margin-bottom: 10px;
}
.layui-icon::after {
  content: "上传论文";
  position: absolute;
  right: 22px;
  top: 9px;
  color: #ffffff;
  font-size: 16px;
}
.clickSm {
  color: #1366e2;
}
.fuzhuSm {
  font-size: 12px;
  padding-top: 6px;
  width: 100%;
  text-align: center;
}
.file-html {
  width: 100%;
  height: 172px;
  position: relative;
  .html-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    .file-title {
      padding-bottom: 16px;
      padding-top: 10px;
      color: #34353c;
      font-size: 16px;
      text-align: center;
    }
    .file-btn {
      .btn {
        display: inline-block;
        width: 110px;
        height: 32px;
        border-radius: 40px;
        line-height: 32px;
        color: #0066ff;
        text-align: center;
        border: 1px solid #3a89ff;
        cursor: pointer;
        transition: 0.6s opacity;
      }
    }
  }
}

.layui-upload-file {
  display: none !important;
  opacity: 0.01;
  filter: Alpha(opacity=1);
}
.input-checkbox{
  position: relative;
  padding-left: 24px;
  line-height: 52px;
  span{
    color: red;
  }

}
.checkboxs{
  width: 16px;
  height: 16px;
  position: absolute;
  top:18px;
  left:0;
}
</style>
